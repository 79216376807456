




import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import FlashMessageMixin from '@/mixins/flashMessageMixin'

const flashMessageModule = namespace('flashMessage')

@Component
export default class NofityOnFailedSubmit extends Mixins(FlashMessageMixin) {
  @Prop({ required: true, type: Boolean }) failed!: boolean
  @Prop({ required: true, type: Boolean }) untouched!: boolean
  @flashMessageModule.Getter getFlashMessage!: flashMessageState

  @Watch('failed')
  @Watch('untouched')
  onFailedChange () {
    if (this.failed === true && this.untouched === true) {
      const message: flashMessage = {
        message: 'Por favor verifique o preenchimento de todos os campos.',
        type: 'warning',
        noTimeout: true
      }
      this.setFlashMessage(message)
      this.scrollToTop()
    } else if (this.getFlashMessage.message === 'Por favor verifique o preenchimento de todos os campos.') {
      this.setFlashMessage()
    }
  }

  beforeDestroy () {
    if (this.getFlashMessage.message === 'Por favor verifique o preenchimento de todos os campos.') {
      this.setFlashMessage()
    }
  }

  scrollToTop () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}
